'use strict';

angular.module('informaApp')
    .directive('infHorizontalbarGanttChart', ["HorizontalbarGanttChartHelper", "GanttChartDataFilter",
        function (HorizontalbarGanttChartHelper, GanttChartDataFilter) {
            return {
                restrict: 'E',
                templateUrl: 'directives/inf-horizontalbar-gantt-chart/template.ptl.html',
                scope: {
                    data: "=",
                    filter: "=",
                    options: '='
                },
                filterData: function (data, filter) {
                    var result = _.merge([], data);
                    return GanttChartDataFilter.filterData(result, filter);
                },
                link: function (scope, element) {
                    var chartContainer = element.find(".chart-container");
                    var this_ = this;
                    let chart;

                    function updateChart(newValue) {
                        if (newValue) {
                            var data = this_.filterData(scope.data, scope.filter);

                            _.forEach(data, x => x.hasInvalidData = GanttChartDataFilter.hasInvalidData(x));

                            if (chart) {
                                chart.destroySVG();
                            }

                            chart = new HorizontalbarGanttChartHelper(chartContainer[0], data, scope.options);
                        }
                    }

                    scope.$watch("data", updateChart);
                    scope.$watch("filter", updateChart, true);

                    scope.$on('$destroy', function () {
                        if (chart) {
                            chart.destroySVG();
                        }
                    });
                }
            }
        }]);